import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalModule, MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { TranslateModule } from '@ngx-translate/core';
import { CarouselModule } from 'primeng/carousel';
import { Subscription } from 'rxjs';
import { API_END_POINTS } from '../../../constants/constant';
import { HttpService } from '../../../services/http.service';
import { LocalStorageServiceService } from '../../../services/local-storage-service.service';
import { ButtonComponent } from '../../../shared/button/button.component';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CarouselModule,
    ButtonComponent,
    MsalModule,
    CommonModule,
    TranslateModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit, OnDestroy {
  private redirectSubscription?: Subscription;
  private loginSubscription?: Subscription;

  constructor(
    private msalSevice: MsalService,
    private localService: LocalStorageServiceService,
    private router: Router,
    private httpService: HttpService
  ) {}

  ngOnInit(): void {
    this.localService.setAccessToken(
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZCI6IjllOGZhNjQwLWM2N2MtNGY0Zi04M2JiLTU5NWNjNjZiMjZiNSIsIk5hbWUiOiJwcmludGVyIiwiUm9sZUlkIjoiMSIsImV4cCI6MTcyNTk3MzY3NywiaXNzIjoibG9jYWxob3N0IiwiYXVkIjoibG9jYWxob3N0In0.x4jeyPFU1GhyIceRBx4OVXFS5lA8fGxt2ufEJ3YV4MM'
    );
    localStorage.setItem(
      'refreshToken',
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZCI6IjEiLCJleHAiOjE3MjU5NzcyNzcsImlzcyI6ImxvY2FsaG9zdCIsImF1ZCI6ImxvY2FsaG9zdCJ9.jDXoVCKi3MOGwHAXsNHHKvk50U4Zd0tFCwZKyq_C3FE'
    );
    localStorage.setItem('userRoleId', '1');
    localStorage.setItem('userName', 'printer');
    this.router.navigate(['/dashboard']);
    this.redirectSubscription = this.msalSevice
      .handleRedirectObservable()
      .subscribe({
        next: (result: AuthenticationResult) => {
          this.msalSevice.instance.setActiveAccount(result?.account);
        },
        error: (error) => {
          console.error('Redirect failed', error);
        },
      });

    if (this.isLoggedIn()) {
      this.router.navigate(['/dashboard']);
    }
  }

  isLoggedIn() {
    return this.msalSevice.instance.getActiveAccount() != null;
  }

  onLogin() {
    this.loginSubscription = this.msalSevice.loginPopup().subscribe({
      next: (response: AuthenticationResult) => {
        this.msalSevice.instance.setActiveAccount(response.account);
        this.postLoginData(response.accessToken);
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  onLogout() {
    this.msalSevice.instance.logout();
  }

  postLoginData(token: string) {
    const url = API_END_POINTS.LOGIN;
    const payload = {
      Token: token,
    };
    this.httpService
      .postRequest(url, payload)
      .then((response) => {
        if (response) {
          this.localService.setAccessToken(response.data.accessToken);
          localStorage.setItem('refreshToken', response.data.refreshToken);
          localStorage.setItem('userRoleId', response.data.roleId);
          localStorage.setItem('userName', response.data.userName);
          this.router.navigate(['/dashboard']);
        } else {
          console.error(response);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  ngOnDestroy(): void {
    if (this.redirectSubscription) this.redirectSubscription?.unsubscribe();
    if (this.loginSubscription) this.loginSubscription?.unsubscribe();
  }
}
